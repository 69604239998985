@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css2?family=Dosis&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}

html, body {
  margin:0px;
  height:100%;
  /* font-family: 'Josefin Sans' !important; */
  /* font-family: 'Dosis', sans-serif !important; */
  font-family: Helvetica Neue, Arial, sans-serif !important; 
}

li {
  margin: 5px 0px 10px;
  font-size: 1.1em;
}

h1 {
  padding-bottom: 10px;
}

img {
  max-width: 100px;
  margin-right: 50px;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading-container {
  background-color: #282c34;
  height:100vh;
}

.loading-subcontainer {
  padding-top: 80px;
}

.loading-title {
  font-size: 28px;
  color: white;
  text-align: left;
}

.loading-spinning-container { 
  transform: scale(1.3)  
}

.loading-spinner {
  margin-top: 30px;
}

.background-img {
  min-height: 733px;
  position: relative;
  z-index: -1;
  max-width: 100%;
}

.landing-image {
  pointer-events: none;
  min-width: 100%;
  offset: 400px;
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
}

@-webkit-keyframes gradient-anim {
  0%{background-position:0% 100%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes gradient-anim {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.shimmer {
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/500% 100%;
  -webkit-animation: shimmer 15s, fadeIn ease 6s;
          animation: shimmer 15s, fadeIn ease 6s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.shimmer-idle {
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/500% 100%;
  -webkit-animation: shimmer 15s infinite;
          animation: shimmer 15s infinite;
}

.comp-zero-opacity {
  opacity: 0;
}

.component-alpha-anim {
  -webkit-animation: componentFade ease 2s forwards;
          animation: componentFade ease 2s forwards;
}

.header-anim {
  -webkit-animation: moving 4s, componentFade ease 4s forwards;
  animation: moving 4s, componentFade ease 4s forwards;
}

@-webkit-keyframes moving {
  from {transform: translateY(30px) scale(1.05);}
  to {transform: translateY(0px) scale(1);}
}

@keyframes moving {
  from {transform: translateY(30px) scale(1.05);}
  to {transform: translateY(0px) scale(1);}
}

.landing-image-anim {
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-animation: scale 4000ms ease-in-out forwards;
          animation: scale 4000ms ease-in-out forwards;
  z-index: 10000;
}

@-webkit-keyframes shimmer {
  100% {-webkit-mask-position:left}
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

@-webkit-keyframes componentFade {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes componentFade {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes scale {
  0% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale {
  0% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.image-container {
    position: relative;
    text-align: center;
    color: #282c34;
}

.title {
  color: rgb(255, 255, 255);
  text-shadow: 3px 3px 10px #010000;
  width: 100%;
  z-index: -1;
  text-align: left;
}

.centered {
    /* left: -15%; */
    font-size: 60px;
    z-index: -1;
    padding-bottom: 100px;
}

.centered-lg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    padding-bottom: 20px;
}

.centered-md {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    padding: 20px;
}

.centered-sm {
    top: 10%;
    left: 35%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    padding: 50px;
}

.centered-xs {
    top: 50%;
    left: 20%;
    transform: translate(-50%, -100%);
    font-size: 40px;
    padding: 20px;
}

.header-subtext {
    font-size: 30px;
}

.header-subtext-md {
    font-size: 25px;
}

.small-text {
    width: 80%;
    /* font-family: "Helvetica Neue"; */
    font-size: 60px;
}

.scrollable-container {
    height:100%;
    background-color:rgb(87, 89, 91);
    /* background: radial-gradient(circle, rgba(48,48,48,1) 0%, rgba(107,107,107,1) 53%, rgba(80,80,80,1) 64%, rgba(167,167,167,1) 85%); */
    background: radial-gradient(circle, rgba(48,48,48,1) 0%, rgba(52,52,52,1) 41%, rgba(222,222,222,1) 67%, rgba(167,167,167,1) 88%);
    background-size: 120% 120%;
    z-index: 1;
    overflow-y: hidden;
    /* animation: gradient-fade 5s, fa  deIn ease 1s; */
    -webkit-animation: gradient-anim 10s ease infinite;
    animation: gradient-anim 10s ease infinite;
}

 .info-container {
     color: floralwhite;
 }

 .border-section {
     /* background-color: floralwhite; */
     border-radius: 5px;
     border-style: solid;
     border-width: 1px;
     border-color: rgb(79, 79, 79);
     color: rgb(57, 57, 57);
 }

 .form-group {
     padding: 0px 10px 20px 10px;
 }

 .contact-container {
  text-align: center;
 }

.contact-header {
    /* color: rgb(255, 255, 255); */
    text-align: center;
    padding-top: 60px;
    font-size: 2em;
}

label {
  font-size: 20px;
  color: white;
}

 .submit-button {
    margin-bottom: 30px;
}

.media-container {
    margin-top: 40px;
}

 .media {
    margin-bottom: 30px;
    box-shadow: 0.1px 1px 10px rgba(0, 0, 0, 0.9);
 }

 .logo {
    max-width: 100px;
 }

 .stack-container {
   margin-bottom: 20px;
 }

 .stack {
   max-width: 60px;
   max-height: 60px;
   margin-right: 25px;
 }

 .round-image {
   border-radius: 15px;
 }


.fade-in {
    animation: fadeIn ease 6s;
    -webkit-animation: fadeIn ease 6s;
    -moz-animation: fadeIn ease 6s;
    -o-animation: fadeIn ease 6s;
    -ms-animation: fadeIn ease 6s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0.3;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

#rcorners4 {
    border-radius: 15px;
    background: #73AD21;
    padding: 20px; 
    /* width: 200px; */
    height: 350px; 
    margin: auto;
    width: 100%;
  }

  .tempo-title {
      font-size: 35px;

      color: blue;
  }

  .tempo-tempo {
    font-size: 50px;
    color: blue;
}

@media only screen and (max-width: 767px){
  img {
    margin-right: 10px;
    max-width: 100px;
    padding: 5px;
  }

  .landing-image {
    padding: 0px;
  }
}

@media only screen and (max-width: 575px){
    .container-xs {
        overflow-x: auto;
    }

    .background-img {
        /* background-image : url("images/landing/MainImage.jpg"); */
        /* background-position: center; */
        min-height: 733px;
        background-attachment: fixed;
        background-position: 60% 0%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: -1;
    }

    .landing-image {
      right: 350px;
      /* bottom: 50px; */
      min-height: 100%;
      object-fit: cover;
      min-width: 200%;
      display: inline-block;
      padding: 0px;
    }

    .landing-image-anim {
      left: -400px;
      top: 0px;
      min-width: 200%;
    }

    .mobile-source {
        /* background-image : url("images/landing/MainImage_Mobile.jpg"); */
        background-size: contain;
        background-size: stretch;
        min-height: 600px;
        /* width: 100%; */
        min-width: 100%;
    }

    .mobile-anim {
      left: -300px;
    }
}

@media only screen and (max-width: 385px){
  .landing-image {
    right: 350px;
    min-height: 100%;
    object-fit: cover;
    min-width: 200%;
    display: inline-block;
    padding: 0px;
  }

  .landing-image-anim {
    left: -500px;
    top: 0px;
    min-width: 300%;
  }
}
